.bg-DEEP_SKY_BLUE {
    background-color: #0b9fd0;
    color: white;
}

.bg-NAVY {
    background-color: #011e3b;
    color: white;
}

.bg-LIGHT_BLACK {
    background-color: #1E1E1E;
    color: white;
}

.bg-theme {
    background-image: linear-gradient(#1E1E1E, #0b9fd0);
    color: white;
}

.bg-theme-r {
    background-image: linear-gradient(to right, #1E1E1E, #0b9fd0);
    color: white;
}

.bg-theme-admin {
    background-color: #011e3b;
    color: white;
    background-image: url('../../assets/nav_nachos@2x.png');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: left 0 bottom 0;
    background-size: 256px 556px;
}

.bg-theme-admin-r {
    background-color: #011e3b;
    color: white;
}

.front-bg {
    background-image: url('../../assets/front-background.png');
    background-size: cover;
}

.app_title {
    font-family: "lobster";
}

/* CSS for pagination */
.pagination {
    margin-top: 10px;
}

.pagination button {
    padding: 5px 10px;
    margin-right: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.pagination button.active {
    background-color: #011e3b;
    color: #fff;
    border-color: #011e3b;
}